<template>
         <table>
            <tr class="head">
                <td class="remove2"> زمان و تاریخ </td>
                <td class="remove"> مبلغ کل </td>
                <td> شماره شبا/کارت </td>
                <td> نوع </td>
                <td> شناسه تراکنش </td>
                <td class="remove"> وضعیت </td>
            </tr>
            <tr v-for="(item,index) in contentTable" :key="index" class="body">
                <td class="remove2"> {{$G2J(item.createdAtDateTime)}} </td>
                <td class="remove"> {{$toLocal(item.amount,0)}} </td>
                <td> {{item.destinationWalletAddress ? item.destinationWalletAddress : item.bankAccount}} </td>
                <td :class="item.bankAccount ? 'fail':'done'"> {{item.bankAccount ? 'برداشت' : 'واریز'}} </td>
                <td> {{item.hashCode ? item.hashCode : '_'}} </td>
                <td class="remove" :class="item.isDone ? 'done':'fail'" > {{item.isDone ? ' موفق ':' ناموفق '}} </td>
            </tr>
        </table>
</template>

<script>
    export default {
        name:'OrderHistory',
        props:['contentTable'],
        data() {
            return {
            }
        },
        methods: {
            reD(e){
                window.location.replace('http://'+e)
            }
        },
    }
</script>

<style lang="scss" scoped>
.link{
    color: var(--primary);
    text-decoration-line:underline;
    cursor: pointer;
}
.container{
    display: flex;
    width: 100%;
}
.fail-btn{
    background: var(--red);
    color: var(--white);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
    width: 100%;
    .head{
        position: sticky;
        top: 0;
        background: var(--white);
        td{
            color: var(--a-hover);
        }
    }
    td{
        width: 10%;
        text-align: center;
        height: 35px;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
@media only screen and(max-width:900px){
    .remove{
        display: none;
    }
}
@media only screen and(max-width:600px){
    // .remove2{
    //     display: none;
    // }
}
</style>