<template>
         <table>
            <tr class="head">
                <td> رمزارز </td>
                <td class="remove"> زمان و تاریخ </td>
                <td> مقدار </td>
                <!-- <td class="remove"> کارمزد </td> -->
                <td class="remove2"> شبکه </td>
                <td class="remove"> آدرس مقصد </td>
                <td> نوع </td>
                <td> شناسه تراکنش </td>
                <td class="remove2"> وضعیت </td>
            </tr>
            <tr v-for="(item,index) in contentTable" :key="index" class="body">
                <td> {{$coinLabel[item.relatedCoin]}} </td>
                <td class="remove"> {{$G2J(item.createdAtDateTime)}} </td>
                <td> {{$toLocal(item.amount)}} </td>
                <!-- <td class="remove"> {{item.wagePercent}}% </td> -->
                <td class="remove2"> {{item.tokenType}} </td>
                <td class="remove2"> {{item.destinationWalletAddress ? item.destinationWalletAddress : '_'}} </td>
                <td :class="item.walletType ? 'fail':'done'"> {{!item.walletType ? 'برداشت' : 'واریز'}} </td>
                <td @click="reD(item.links)" class="link"> مشاهده جزئیات </td>
                <td class="remove" :class="item.isDone ? 'done':'fail'" > {{item.isDone ? ' موفق ':' ناموفق '}} </td>
            </tr>
        </table>
</template>

<script>
    export default {
        name:'OrderHistory',
        props:['contentTable'],
        data() {
            return {
            }
        },
        methods: {
            reD(e){
                window.location.replace('http://'+e)
            }
        },
    }
</script>

<style lang="scss" scoped>
.link{
    color: var(--primary);
    text-decoration-line:underline;
    cursor: pointer;
}
.container{
    display: flex;
    width: 100%;
}
.fail-btn{
    background: var(--red);
    color: var(--white);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
    width: 100%;
    .head{
        position: sticky;
        top: 0;
        background: var(--white);
        td{
            color: var(--a-hover);
        }
    }
    td{
        width: 10%;
        text-align: center;
        height: 35px;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
@media only screen and(max-width:900px){
    .remove{
        display: none;
    }
}
// @media only screen and(max-width:600px){
//     .remove2{
//         display: none;
//     }
// }
</style>