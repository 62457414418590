<template>
    <perfect-scrollbar @ps-y-reach-end="getOrders">
         <table>
            <tr class="head">
                <td> بازار </td>
                <td class="remove"> زمان و تاریخ </td>
                <td class="remove2"> سمت </td>
                <td class="remove"> نوع </td>
                <td > قیمت واحد </td>
                <td> مقدار </td>
                <!-- <td class="remove"> کارمزد </td> -->
                <td class="remove2"> مبلغ کل </td>
                <td class="remove"> پر شده </td>
                <td class="remove2"> وضعیت </td>
            </tr>
            <tr v-for="(item,index) in tradeHistory" :key="index" class="body">
                <td> {{item.marketType}} </td>
                <td class="remove"> {{$G2J(item.createdAtDateTime)}} </td>
                <td class="remove2" :class="item.orderType.includes('BUY') ? 'color-green':'fail'" > {{item.orderType.includes('BUY') ? ' خرید ':' فروش '}} </td>
                <td class="remove" v-if="item.orderType.includes('LIMITED')" > حد </td>
                <td class="remove" v-if="item.orderType.includes('MARKET')" > بازار </td>
                <td class="remove" v-if="item.orderType.includes('EXCHANGE')" > صرافی </td>
                <td class="remove2"> {{$toLocal(item.unitPrice)}} </td>
                <td> {{$toLocal(item.amount)}} </td>
                <td> {{$toLocal(item.wholePrice)}} </td>
                <td class="remove"> {{$toLocal(item.executedPercent,2)}}% </td>
                <td v-if="item.orderStatus.includes('COMPLETED') || item.orderStatus.includes('FINISHED')" class="remove2 done"> تکمیل </td>
                <td v-if="item.orderStatus.includes('CANCELLED')" class="remove2 fail"> لغو شده </td>
                <td v-if="item.orderStatus.includes('EXPIRED')" class="remove2 expired"> انقضا </td>
            </tr>
        </table>
    </perfect-scrollbar>
</template>

<script>
    export default {
        name:'OrderHistory',
        data() {
            return {
                tradeHistory:[],
                page:0,
                lastPage:1,
            }
        },
        methods: {
            async getOrders(){
                if (this.page < this.lastPage) {
                    this.page++
                const res = await this.$axios.get('/orders', {
                    params: {
                        page:this.page,
                        size:10,
                    }
                })
                    this.lastPage = res.totalPages
                    let newData = res.content.filter(a => a.orderStatus !== 'IS_OPEN')
                    this.tradeHistory=[...this.tradeHistory,...newData]
                }
            },
        },
        mounted() {
            this.getOrders();
        },
    }
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    width: 100%;
}
.fail-btn{
    background: var(--red);
    color: var(--white);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
    width: 100%;
    .head{
        position: sticky;
        top: 0;
        background: var(--white);
        td{
            color: var(--a-hover);
        }
    }
    td{
        width: 10%;
        text-align: center;
        height: 35px;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
@media only screen and(max-width:900px){
    .remove{
        display: none;
    }
}
// @media only screen and(max-width:600px){
//     .remove2{
//         display: none;
//     }
// }
</style>