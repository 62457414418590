<template>
    <perfect-scrollbar @ps-y-reach-end="getData">
         <table>
            <tr class="head">
                    <td class="remove"> زمان </td>
                    <td> آی پی </td>
                    <td> نوع دستگاه </td>
                    <td class="remove"> پلتفرم </td>
                    <td> نوع مرورگر </td>
                    <td class="remove"> وضعیت </td>
            </tr>
            <tr v-for="(item,index) in tableContents" :key="index" class="body">
                    <td class="remove"> {{$G2J(item.loginRequestedDateTime)}} </td>
                    <td> {{item.ipAddress}} </td>
                    <td> {{item.deviceType}} </td>
                    <td class="remove"> {{item.osType}} </td>
                    <td> {{item.browser}} </td>
                    <td class="remove" :class="item.isSuccessful ? 'done':'fail'" > {{item.isSuccessful ? ' موفق ':' ناموفق '}} </td>
            </tr>
        </table>
    </perfect-scrollbar>
</template>

<script>
    export default {
        name:'OrderHistory',
        data() {
            return {
                tableContents:[],
                page:0,
                lastPage:1,
            }
        },
        methods: {
            async getData() {
                if (this.page <= this.lastPage && !this.loading) { //for scroll loading
                    this.page++ //for scroll loading
                    const [res,] = await this.$http.get('/users/login-history', {
                        params: {
                            size: 10,
                            page: this.page
                        }
                    })
                    if (res) {
                        this.lastPage = res.totalPages
                        this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                    }
                }
                this.state.loading = false
            },
        },
        mounted() {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
.link{
    color: var(--primary);
    text-decoration-line:underline;
    cursor: pointer;
}
.container{
    display: flex;
    width: 100%;
}
.fail-btn{
    background: var(--red);
    color: var(--white);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
    width: 100%;
    .head{
        position: sticky;
        top: 0;
        background: var(--white);
        td{
            color: var(--a-hover);
        }
    }
    td{
        width: 10%;
        text-align: center;
        height: 35px;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
@media only screen and(max-width:900px){
    .remove{
        display: none;
    }
}
// @media only screen and(max-width:600px){
//     .remove2{
//         display: none;
//     }
// }
</style>