<template>
    <div class="history">
        <div class="table-container">
            <div class="btns-container">
                <button v-for="(item,index) in historyDrop" :key="index" @click="changeTab(item)" :class="{'selected-btn':historyTab==item}" class="filter-btn"> {{$tabTranslator[item]}} </button>
                <div @click="dropShow = !dropShow" class="drop-container">
                    <inline-svg :src="require('../../../assets/Icons/arrow.svg')" :class="{'rotate':dropShow}" class="svg"/>
                    <span class="drop-title"> {{$tabTranslator[historyTab]}} </span>
                    <div v-if="dropShow" class="drop-down">
                        <span @click="changeTab(item)" v-for="(item,index) in historyDrop" :key="index" class="drop-items"> {{$tabTranslator[item]}} </span>
                    </div>
                </div>
            </div>
            <div :class="$route.name=='AdvanceTrade' ? 'high-over' : 'low-over'" class="tables">
                <ActiveOrders v-if="historyTab=='activeOrders'" />
                <OrderHistory v-if="historyTab=='orderHistory'" />
                <TradeHistory v-if="historyTab=='tradeHistory'" />
                <perfect-scrollbar v-if="historyTab=='cryptoTransaction' || historyTab=='rialTransaction'" @ps-y-reach-end="getTransactions">
                <CryptoTransaction :contentTable="transactionsTableCrypto" v-if="historyTab=='cryptoTransaction'" />
                <RialTransaction :contentTable="transactionsTableRial" v-if="historyTab=='rialTransaction'" />
                </perfect-scrollbar>
                <LoginHistory v-if="historyTab=='loginHistory'" />
            </div>
        </div>
    </div>
</template>

<script>
import OrderHistory from './OrderHistory.vue'
import TradeHistory from './TradeHistory.vue'
import CryptoTransaction from './CryptoTransaction.vue'
import RialTransaction from './RialTransaction.vue'
import LoginHistory from './LoginHistory.vue'
import ActiveOrders from './ActiveOrders.vue'
    export default {
        name:'history',
        components:{
            OrderHistory,
            TradeHistory,
            CryptoTransaction,
            RialTransaction,
            LoginHistory,
            ActiveOrders
        },
        data() {
            return {
                page:0,
                lastPage:1,
                transactionsTableRial:[],
                transactionsTableCrypto:[],
                dropShow:false,
                historyTab:'orderHistory',
                historyDrop:['activeOrders','orderHistory','tradeHistory','cryptoTransaction','rialTransaction','loginHistory'],
            }
        },
        methods: {
            changeTab(val){
                this.historyTab = val
                this.page = 0
                this.lastPage = 1
                this.getTransactions()
            },
            async getTransactions(){
                if(this.historyTab=='rialTransaction' || this.historyTab=='cryptoTransaction'){
                if (this.page < this.lastPage) {
                    this.page++
                const res = await this.$axios.get('/wallets/deposit', {
                    params: {
                        size:10,
                        page:this.page,
                    }
                })
                const res2 = await this.$axios.get('/wallets/withdrawal-requests', {
                    params: {
                        size:10,
                        page:this.page,
                    }
                })
                res.totalPages > res2.totalPages ? this.lastPage = res.totalPages : this.lastPage = res2.totalPages
                let allTransactions =[]
                allTransactions = [...this.transactionsTableRial,...this.transactionsTableCrypto,...res.content,...res2.content]
                this.transactionsTableRial = allTransactions.filter( a => a.relatedCoin =='TOMAN' )
                this.transactionsTableCrypto = allTransactions.filter( a => a.relatedCoin !=='TOMAN' )
                }
            }
            },
        },
        mounted() {
            if(this.$route.name=='AdvanceTrade'){
                this.historyDrop=['activeOrders','orderHistory','tradeHistory']
            }else{
                this.historyDrop=['orderHistory','tradeHistory','cryptoTransaction','rialTransaction','loginHistory']
            }
            this.getTransactions();
        },
        created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.drop-container')){
                    this.dropShow=false;
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.high-over{
    max-height: 300px;
    overflow-y: scroll;
    padding: 0px !important;
}
.low-over{
    max-height: 70vh;
    overflow: scroll;
}
.svg{
    fill: var(--a-hover) !important;
}
.drop-container{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    width: 100%;
    padding: 4px;
    align-items: center;
    position: relative;
    .drop-title{
        color: var(--a-hover);
        font-size: .8em;
    }
    .drop-down{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 150px !important;
        position: absolute;
        top: 30px;
        background: #F0F0F5;
        box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
        border-radius: 4px;
        z-index: 6;
        padding: 8px;
        .drop-items{
            text-align: start;
            padding: 4px;
            font-size: .8em;
        }
    }
}



.history{
    display: flex;
    width: 100%;
    overflow-x: scroll;
}
.table-container{
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 370px;
    margin: 10px;
    .btns-container{
        display: flex;
        flex-direction: row;
        width: 75%;
        .filter-btn{
            width: 20%;
            color: var(--a-hover);
            font-size: .8em;
            text-align: center;
            cursor: pointer;
            padding: 4px 0;
            transition: 700ms;
            transform: translate(0px,0px);
            height: 40px;
        }
        .selected-btn{
            background: var(--white);
            // box-shadow: 0px 10px 12px -10px rgba(65, 70, 192, 0.2) inset;
            box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
            border-radius: 4px 4px 0px 0px;
            color: var(--primary) !important;
            z-index: 3;
            &::after{
                content: ' ';
                width: 100%;
                height: 100%;
                position: absolute;
                box-shadow: 0px 10px 8px -1px var(--white);
            }
        }
    }
    .tables{
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        background: var(--white);
        box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.3);
        border-radius: 4px ;
        // padding: 12px 20px;
    }
}
@media only screen and(max-width: 1200px){
    .btns-container{
        width: 100% !important;
    }
}
@media only screen and(max-width: 850px){
    .btns-container{
        width: 100% !important;
    }
    .filter-btn{
        display: none;
    }
}
@media only screen and(min-width: 850px){
    .drop-container{
        display: none;
    }

}
</style>