<template>
<perfect-scrollbar @ps-y-reach-end="getOrders">
    <table>
            <tr class="head">
                <td style="text-align:start"> بازار </td>
                <td class="remove"> زمان و تاریخ </td>
                <td class="remove"> سمت </td>
                <td> قیمت واحد </td>
                <td> مقدار </td>
                <td class="remove"> کارمزد </td>
                <td class="remove2"> مبلغ کل </td>
                <td class="remove"> پر شده </td>
                <td style="text-align: end;display: flex;width: 100%;justify-content: end;"> 
                    <button @click="candelAllOrder" class="fail-btn"> لغو همه </button>     
                </td>
            </tr>
            <tr v-for="(item,index) in tradeHistory" :key="index" class="body">
                <td style="text-align:start"> {{item.marketType}} </td>
                <td> {{$G2J(item.createdAtDateTime)}} </td>
                <td class="remove" :class="item.orderType.includes('BUY') ? 'color-green':'fail'" > {{item.orderType.includes('BUY') ? ' خرید ':' فروش '}} </td>
                <td class="remove2"> {{$toLocal(item.unitPrice)}} </td>
                <td> {{$toLocal(item.amount)}} </td>
                <td class="remove"> {{item.wagePercent}}% </td>
                <td class="remove2"> {{$toLocal(item.wholePrice)}} </td>
                <td class="remove"> {{item.executedPercent*100}}% </td>
                <td @click="candelOrder(item.id)" class="fail" style="text-align:end;padding-left:14px; cursor:pointer"> لغو سفارش </td>
            </tr>
    </table>
</perfect-scrollbar>
</template>

<script>
    export default {
        name:'ActiveOrders',
        // props:['page','lastPage'],
        data() {
            return {
                tradeHistory:[],
                page:0,
                lastPage:1,
            }
        },
        methods: {
            async candelAllOrder() {
                this.$swal.fire({
                        title: 'لغو سفارش',
                        text: "تمام سفارشات شما لغو خواهد شد و قابل برگشت نخواهد بود ",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'بازگشت',
                        confirmButtonText: 'لفو سفارشات'
                        }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.state.loading = true
                            const [res,] = await this.$http.delete(`orders/all?marketType=${this.$route.params.tradeFrom+'_'+this.$route.params.tradeTo}`)
                            console.log(res);
                            if (res) {
                                ++this.state.orderChanged
                                this.getOrders()
                                this.$swal.fire(
                                'لغو همه سفارشات',
                                'سفارشات شما با موفقیت لغو شد',
                                'success'
                                )
                                this.state.orderChanged++
                            }
                        }
                 })
            },
            async candelOrder(e) {
                this.$swal.fire({
                        title: 'لغو سفارش',
                        text: "سفارش شما لغو خواهد شد و قابل برگشت نخواهد بود ",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'بازگشت',
                        confirmButtonText: 'لفو سفارش'
                        }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.state.loading = true
                            const [res,] = await this.$http.delete(`orders/${e}`)
                            console.log(res);
                            if (res) {
                                ++this.state.orderChanged
                                this.getOrders()
                                this.$swal.fire(
                                'لغو سفارش',
                                'سفارش شما با موفقیت لغو شد',
                                'success'
                                )
                            }
                        }
                 })
            },
            async getOrders(){
                if (this.page < this.lastPage) {
                    this.page++
                const res = await this.$axios.get('/orders', {
                    params: {
                        orderStatus: 'IS_OPEN',
                        market: this.$route.params.tradeFrom+'_'+this.$route.params.tradeTo ,
                        size:10,
                        page:this.page,
                    }
                })
                this.lastPage = res.totalPages
                let newData = res.content.filter(a => a.orderStatus == 'IS_OPEN')
                this.tradeHistory=[...this.tradeHistory,...newData]
                }
            },
        },
        mounted() {
            this.getOrders();
        },
    }
</script>

<style lang="scss" scoped>
.fail-btn{
    background: var(--red);
    color: var(--white);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
    width: 100%;
    .head{
        position: sticky;
        top: 0;
        background: var(--white);
        td{
            color: var(--a-hover);
        }
    }
    td{
        width: 10%;
        text-align: center;
        height: 35px;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
@media only screen and(max-width:900px){
    .remove{
        display: none;
    }
}
// @media only screen and(max-width:600px){
//     .remove2{
//         display: none;
//     }
// }
</style>